import { FilterService } from './filters/filter.service';
import { BreakpointService } from './../../core/services/breakpoint.service';
import { UtilService } from './../../services/util.service';
import { distinctUntilChanged, last, switchMap, mergeMap, tap } from 'rxjs/operators';
import { RequestService } from './../../services/request.service';
import { ConfigService } from './../../services/config.service';
import { GroupService } from './../../services/group.service';
import { CreationService } from 'src/app/pages/creation/creation.service';
import { ViewEncapsulation,Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-historical-request',
  templateUrl: './historical-request.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../../../styles/bootstrap.min.css','./historical-request.component.scss']
})
export class HistoricalRequestComponent implements OnInit {
  showFilters = false;
  siteUrl = environment.siteUrl;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private configService: ConfigService,
              private groupService: GroupService,
              private utilService: UtilService,
              private filterService: FilterService,
              public requestService: RequestService,
              private breakpointService: BreakpointService,
              private creationService: CreationService,
              private oauthService: OAuthService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params['extranet']) 
        this.configService.extranet = params['extranet'];
        
      /*if (params['profileName'])
        this.configService.profileName = params['profileName'];
        console.log('profile :' + this.configService.profileName);
        */
      });
      
    console.log('extra :' + this.configService.extranet);

    //  wait for SSO server
        if(this.configService.profileName !== undefined){
            this.getRequests()
        }
        else {
            if(!this.oauthService.hasValidAccessToken()){//token ou pas encore ?
                setTimeout(() => {
                    this.getInit()
                }, 1000);
            } else {
                this.getInit()
            }
        }
  }

  getGroups(): void {
        this.groupService.getGroups()
          .subscribe(g => this.creationService.groups = g.groups);
  }
  showDetail(requestId: number) {
    this.requestService.selectedId = requestId;
    if (this.breakpointService.isDesktop) {
      this.router.navigate(['/historical/requests-detail', requestId], { skipLocationChange: true });
    } else {
      this.router.navigate(['/requests-detail', requestId], { skipLocationChange: true });
    }
  }

  getRequests() {
    this.requestService.getRequestsByDomain().subscribe(requests => this.requestService.requests = requests.requests.map(request => {
                let groupName = this.configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN;
                let typeName = this.configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN;
                request.name = typeName ? typeName : groupName;
                request.visible = true;
                return request}));
  }
  getInit() {
    this.configService.getConfig().pipe(
      tap(resp => console.log("config", resp.body)),
      tap(resp => this.configService.bffToken = resp.headers.get("msx-bff-token")),
      tap(resp => (
        this.configService.domain = resp.body.domain, 
        this.configService.lang = (resp.body.lang === 'FRA') ? 'fr-FR' : 'en-US',
        localStorage.setItem('language', this.configService.lang),
        this.configService.profileName = resp.body.profileName,
        this.configService.attachmentRules = resp.body.attachmentRules)),
      switchMap(() => (
        this.requestService.getRequestsByDomain()).pipe(
          tap(requests => this.requestService.requests = requests.requests.map(request => {
            let groupName = this.configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN;
            let typeName = this.configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN;
            request.name = typeName ? typeName : groupName;
            request.visible = true;
            return request})),
          //last()  //  only fire subscribe once for switchMap
        ),

      )
    ).subscribe(() => {
        this.subscribeFilters();
        this.getGroups()
    });
  }

  toggleFilters() {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      this.filterService.applyFilters(this.requestService.requests);
    }
  }

  subscribeFilters() {
    console.log('subscribeFilters')
    this.filterService.cleanFilters();
    this.filterService.filteredRequests$.subscribe((filteredRequests) => {
      this.requestService.requests = filteredRequests
    });
  }

  hasNoResult(): boolean {
    return !this.requestService.hasVisibleRequests() && this.filterService.hasAnyFilter();
  }

  format(date: Date) {
    return this.utilService.format(date, this.configService.lang);
  }
  statusLabel(status: string) {
    return this.utilService.getStatusLabel(status);
  }
  groupImage(groupId: number) {
    return this.utilService.getGroupImageUrl(groupId);
  }
  isEmpty(text: string): boolean {
    return (text === null) || text.trim() === ''; 
  }

  filterIcon(): string {
    if (this.filterService.hasAnyFilter()) {
      return this.siteUrl + '/assets/filter/icon_filters-select.svg';
    }
    return this.siteUrl + '/assets/filter/icon_filters.svg';
  }


}

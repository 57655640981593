import { AdvisorCreationComponent } from './pages/advisor-creation/advisor-creation.component';
import { RequestDetailComponent } from './pages/request-detail/request-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { RouterTestingModule } from '@angular/router/testing';
import { HistoricalRequestComponent } from './pages/historical-request/historical-request.component';
import { CreationComponent } from './pages/creation/creation.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { AttachmentsComponent } from './pages/creation/attachments/attachments.component';
import { AuthGuardService as AuthGuard } from './core/auth/auth-guard.service';

const routes = [
    { path: 'advisor', component: RequestDetailComponent},
    { path: 'creation', component: CreationComponent, canActivate: [AuthGuard]},
    { path: 'advisor-creation', component: AdvisorCreationComponent},
    { path: 'attachments', component: AttachmentsComponent, canActivate: [AuthGuard] },
    { path: 'requests-detail/:id', component: RequestDetailComponent, canActivate: [AuthGuard] },
    { path: 'historical', component: HistoricalRequestComponent, children: [
      //{ path: 'mainpage', component: MainPageComponent},
      { path: 'requests-detail/:id', component: RequestDetailComponent , canActivate: [AuthGuard]}
    ]},
    { path: '**', component: HistoricalRequestComponent, children: [
      { path: '', component: MainPageComponent}
    ]},
  ]

@NgModule({
  imports: [RouterTestingModule.withRoutes(routes)],
  //exports: [RouterTestingModule]
  //imports: [RouterModule.forRoot(routes, {enableTracing: true})],
  providers:[AuthGuard],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { BffTokenInterceptor } from './core/auth/BffTokenInterceptor';
import { ErrorInterceptor } from './interceptor/errorInterceptor';
import { HistoricalRequestModule } from './pages/historical-request/historical-request.module';
import { RequestDetailComponent } from './pages/request-detail/request-detail.component';
import { DoBootstrap, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {APP_BASE_HREF} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainPageComponent } from './pages/main-page/main-page.component';
import { CreationModule } from './pages/creation/creation.module';
import { createCustomElement } from '@angular/elements';

import { InternationalizationModule } from './internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AdvisorCreationModule } from './pages/advisor-creation/advisor-creation.module';

/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.siteUrl + '/assets/locales/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    RequestDetailComponent,
    MainPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CreationModule,
    AdvisorCreationModule,
    HistoricalRequestModule,
    FormsModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OAuthModule.forRoot({
      resourceServer: {
          allowedUrls: [environment.bffUrl],
          sendAccessToken: true
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: BffTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {provide: APP_BASE_HREF, useValue: '/'}
  ],
  entryComponents: [AppComponent]
  //bootstrap: [AppComponent]
})
export class AppModule implements DoBootstrap { 
  constructor(private injector: Injector) {}


  ngDoBootstrap() {
    const custom = createCustomElement(AppComponent, {injector: this.injector});
    customElements.define('app-gsms', custom);
  }
  
}


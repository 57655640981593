import { Group } from './../../models/group';
import { CreationService } from 'src/app/pages/creation/creation.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service';

@Component({
  selector: 'app-advisor-creation',
  templateUrl: './advisor-creation.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./advisor-creation.component.scss']
})
export class AdvisorCreationComponent implements OnInit {

  siteUrl = environment.siteUrl;

  constructor(private configService: ConfigService, public creationService: CreationService) { 

    document.body.style.margin = "0";

  }
  
  ngOnInit(): void {
    
    const group: Group = { id: 4, nameFR: "Demande d'information", nameEN: "Request for information", types: null };

    //this.creationService.selectedGroup = this.creationService.groups.find(group => group.id === 4);
    this.creationService.selectedGroup = group;
    this.creationService.selectedType = null;
    console.log(this.creationService);

  }

  cancel(){
    this.creationService.clean();
  }

}

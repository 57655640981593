import { ModalService } from './services/modal.service';
import { Component, ElementRef } from '@angular/core';
import { Modal } from './models/modal';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ConfigService } from './services/config.service';
import { LocalizationService } from './internationalization/localization.service';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { authCodeFlowConfig } from './core/auth/auth-config';

@Component({
  selector: 'app-gsms',
  templateUrl: './app.component.html',
  styleUrls: ['../styles/bootstrap.min.css','./app.component.scss']
})
export class AppComponent {
  title = 'messagerie-front';
  modal: Modal;
  siteUrl = environment.siteUrl;

  constructor(
    private router: Router, 
    private modalService: ModalService,
    private configService: ConfigService,
    private elementRef: ElementRef,
    private localizationService: LocalizationService,
    private oauthService: OAuthService
  ) {

  }

  ngOnInit() {

    //let language = this.elementRef.nativeElement.getAttribute('data-language') || 'fr-FR';
    let extra = this.elementRef.nativeElement.getAttribute('data-extra') || 'EXA';
    //let profile = this.elementRef.nativeElement.getAttribute('data-profile') || 'EXAAdherent';
    if (extra === 'ERH') {
        this.configService.ssoRedirectUrl = environment.ssoRedirectUrlERH;
    } else if (extra === 'EAC') {
        this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEAC;
    } else if (extra === 'EXA'){
        this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEXA;
    } else if (extra === 'EXI'){
        this.configService.ssoRedirectUrl = environment.ssoRedirectUrlEXA;
    } else {
        this.configService.ssoRedirectUrl = environment.ssoRedirectUrl;
    }
    console.log(environment.ssoRedirectUrl);
    if (extra === 'EXA-dev'){
        this.configService.extranet = 'EXA';
    } else {
        this.configService.extranet = extra;
    }
    //this.configService.profileName = profile;
    //this.configService.lang = language === 'en-US' ? 'en-US' : 'fr-FR';

    this.router.initialNavigation();
    this.modalService.modal$.subscribe(m => {
      this.modal = m;
    });

    this.localizationService.initService();
    if (environment.ssoEnabled){
          this.oAuthConfig();
    }
  }

  hideModal(): void {
    this.modalService.hide();
  }

  oAuthConfig(): void {
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        console.warn('OAuthEvent Object:', event);
      }
    });
    authCodeFlowConfig.redirectUri = this.configService.ssoRedirectUrl;
    this.oauthService.configure(authCodeFlowConfig);


    this.oauthService.tryLogin().then(()=> {
      if (!this.oauthService.hasValidAccessToken()) {
        console.log('initCodeFlow');
        this.oauthService.initCodeFlow();
      }
    }).then(() => {
      //console.log('claims', this.oauthService.getIdentityClaims());
      //console.log('id token', this.oauthService.getIdToken());
      console.log('access token', this.oauthService.getAccessToken());
      console.log('hasValidAccessToken', this.oauthService.hasValidAccessToken());
      let claims = this.oauthService.getIdentityClaims();
       if (claims)
         console.log(claims);
    })
  }
   openPost(url): void {
      var formElement = document.createElement("form");
      formElement.setAttribute("method", "post");
      formElement.setAttribute("action", url);
      formElement.setAttribute("target", "_parent");
      document.body.appendChild(formElement);
      formElement.submit();
  }
  getCookie(name: string): string {
    	const nameLenPlus = (name.length + 1);
    	return document.cookie
    		.split(';')
    		.map(c => c.trim())
    		.filter(cookie => {
    			return cookie.substring(0, nameLenPlus) === `${name}=`;
    		})
    		.map(cookie => {
    			return decodeURIComponent(cookie.substring(nameLenPlus));
    		})[0] || null;
    }
}

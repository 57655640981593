<router-outlet></router-outlet>

<div *ngIf="modal" class="modal show" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-signal"><img src='{{siteUrl}}/assets/icon_danger.svg' class="" width="25" ></div>
            <div class="modal-title-body">
                <div class="modal-header">
                    <h5 class="modal-title">{{modal.title}}</h5>
                    <a (click)="hideModal()" class="btn-close-modal"><img src='{{siteUrl}}/assets/icon_remove.svg' width="15"></a>
                </div>
                <div class="modal-body">
                    <p *ngIf="modal.text">{{modal.text}} class="modal-p"</p>
                    <p *ngFor="let text of modal.texts" class="modal-p">
                        {{text}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

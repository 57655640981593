<div class="row-msx" *ngIf="binding">
    <div class="col">

        <div class="mx-auto" >
            <h2 class="h2-msx group-title justify-content-center">{{ 'attachment.title' | translate }}</h2>
        </div>
        <div class="container">
            <div class="row-msx justify-centent-center">
                <div class="mx-auto col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8">

                    <div [class]="advisorPage === true ? 'attachments-advisor row-msx' : 'attachments row-msx'" appUpload (onFileDropped)="uploadFile($event)">
                        
                        <h3 *ngIf="!binding.attachmentRule.required && !advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentTextOptional' | translate }}</h3>
                        <h3 *ngIf="binding.attachmentRule.required && !advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentTextRequired' | translate }}</h3>
                        
                        <h3 *ngIf="!binding.attachmentRule.required && advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentAdvisorTextOptional' | translate }}</h3>
                        <h3 *ngIf="binding.attachmentRule.required && advisorPage" class="h3-msx min-width">{{ 'attachment.attachmentAdvisorTextRequired' | translate }}</h3>

                        <input name="image[]"
                                id="image"
                                class="uploadInput"
                                type="file"
                                #fileUpload
                                multiple
                                (change)="uploadFile($event.target.files)">

                        <div class="col-6 col-xs-6 col-sm-6 col-md-5 col-lg-5 col-xl-5" *ngIf="creationService.uploadFiles.length < binding.attachmentRule.maxNb">
                            <button class="btnCustom my-3" (click)="fileUpload.click()">{{ 'attachment.add' | translate }}<img class="icon-plus" src='{{siteUrl}}/assets/icon_plus.svg'></button>
                        </div>

                        <div [class.maxSize]="isFilesSizeTooLarge(creationService.uploadFiles)" [class.mx-auto]="creationService.uploadFiles.length >= binding.attachmentRule.maxNb" class="col-6 col-xl-4 col-lg-3 col-md-3 col-sm-6 col-xs-6 files-size" >
                            <div class="col-sm-12 col-xl-5 text-size">{{ 'attachment.totalSize' | translate }} :</div><div class="col-sm-12 col-xl-7 text-size"> {{formatBytes(filesSize,2)}}/ 15 Mo</div>
                        </div>

                        <div class="row-msx mt-3" style="width: 100%;">
                            <div *ngFor="let file of creationService.uploadFiles" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                <div class="file">
                                    <img class="icon-close" src='{{siteUrl}}/assets/icon_remove.svg' (click)="deleteFile(file)"/>
                                    <img class="icon-file" src='{{siteUrl}}/assets/icon_file.svg' >
                                    <div class="file-name" >{{(file.name.length>25)? (file.name | slice:0:25)+'...':(file.name) }}</div>
                                    <div class="file-size">{{formatBytes(file.size)}}</div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!binding.attachmentRule.messageRequired" class="message-title">{{ 'attachment.addMessage' | translate }}<span class="message-optional">({{ 'attachment.optional' | translate }})</span></div>
                        <div *ngIf="binding.attachmentRule.messageRequired && !advisorPage" class="message-title">{{ 'attachment.addMessageRequired' | translate }} *</div>
                        <div *ngIf="binding.attachmentRule.messageRequired && advisorPage" class="message-title">{{ 'attachment.advisorAddMessageRequired' | translate }} *</div>

                        <div [class]="advisorPage === true ? 'col-md-12 my-2' : 'col-md-9 my-2'">
                            <textarea 
                                (keyup)="onKeyUp($event)"  
                                id="requestMessage" 
                                name="requestMessage" 
                                [(ngModel)]="creationService.requestMessage" 
                                [class]="advisorPage === true ? 'textarea form-control-advisor' : 'textarea form-control'"
                                [maxlength]="advisorPage === true ? 2000 : 1000" 
                                minlength="0" 
                                placeholder="{{ 'attachment.messageText' | translate }}"
                                spellcheck="true">
                            </textarea>
                            <div class="char-counter">{{messageLength}}/{{advisorPage === true ? 2000 : 1000}}</div>
                        </div>

                        <div class="row-msx justify-content-center sendButton">
                            <button [disabled]="!creationService.formValid || submited" class="btnCustom" (click)="sendRequest()">{{ 'attachment.send' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<ngx-spinner  bdColor="rgba(51, 51, 51, 0.8)" template="<img src='{{siteUrl}}/assets/loader.gif'/>"></ngx-spinner>


import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";
import { EMPTY, Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConfigService } from "src/app/services/config.service";
import { environment } from "src/environments/environment";


@Injectable({
    providedIn: 'root'
})
export class BffTokenInterceptor implements HttpInterceptor {

    URL_CONFIGURATIONS = environment.bffUrl + '/v1/configurations';

    constructor(private configService: ConfigService, private oAuthService: OAuthService, ) {
    }

    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.configService.bffToken && (httpRequest.method != 'OPTIONS') && (httpRequest.url.startsWith(environment.bffUrl) && !httpRequest.url.startsWith(this.URL_CONFIGURATIONS))) {
            const authReq = httpRequest.clone({
                headers: httpRequest.headers.set('msx-bff-token', this.configService.bffToken)
            });
            console.log('add msx-bff-token to header');
            return next.handle(authReq).pipe(catchError(err => this.handleError(err, httpRequest)));
        } else {
            return next.handle(httpRequest).pipe(catchError(err => this.handleError(err, httpRequest)));
        }
    }

    handleError(err: HttpErrorResponse, httpRequest: HttpRequest<any>): Observable<any> {
        if (environment.ssoEnabled && httpRequest.url.startsWith(environment.bffUrl) && (err.status === 401 || err.status === 403) && (httpRequest.headers.get('Authorization') !== undefined)) {
            console.log('handle 401 | 403 bff error');
            
            this.oAuthService.logOut(); // remove all oAuth tokens
            this.oAuthService.initCodeFlow();
            //EMPTY completes the observable
            return EMPTY; 
        }
        return throwError(err);
    }
}


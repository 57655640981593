import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Requests } from '../models/requests';
import { Request } from '../models/request';
import { RequestForm } from '../models/request-form';
import { RequestDetail } from './../models/request-detail';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  url = environment.bffUrl + '/v1';
  urlAdvisor = environment.advisorUrl + '/v1';
  requests: Request[] = [];
  selectedId: number = null;

  constructor(private http: HttpClient) { }

  hasVisibleRequests() {
    return (this.requests.find(r => r.visible)); 
  }

  /**
   * get request by domain
   */
  getRequestsByDomain(): Observable<Requests> {
    return this.http.get<Requests>(this.url + "/requests");
  }

  getRequestDetail(requestId: number): Observable<RequestDetail> {
    //  count client documents
    let count = 0;
    return this.http.get<RequestDetail>(this.url + "/requests-detail/" + requestId).pipe(
      map(r => {
        r.messages.forEach(m => count += m.documents.length);
        r.clientDocumentCount = count;
        return r;
      })
    );
  }
  getRequestDetailForAdvisor(requestId: number): Observable<RequestDetail> {
      //  count client documents
      let count = 0;
      return this.http.get<RequestDetail>(this.urlAdvisor + "/requests-detail/" + requestId).pipe(
        map(r => {
          r.messages.forEach(m => count += m.documents.length);
          r.clientDocumentCount = count;
          return r;
        })
      );
    }

  createRequest(request: RequestForm, files: any[]) {
    var form = new FormData();
    if (files) {
      for  (var i =  0; i <  files.length; i++)  {
        form.append("files",  files[i]);
      }
    }
    form.append("label", request.label);
    form.append("groupId", request.groupId.toString());
    if (request.typeId) {
      form.append("typeId", request.typeId.toString()); //  for spring, can't cast "null" to Long
    }
    form.append("comment", request.comment);
    
    return this.http.post<any>(this.url + "/requests", form);
  }
}

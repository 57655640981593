import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Binding } from '../models/binding';

@Injectable({
  providedIn: 'root'
})
export class BindingService {
  url = environment.bffUrl + '/v1';

  constructor(private http: HttpClient) { }

    /**
   * get binding
   */
     getBinding(groupId: number, typeId?: number): Observable<Binding> {
      return this.http.get<Binding>(this.url + "/bindings?groupId="+groupId + (typeId ? "&typeId="+typeId : ''));
    }
  
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient,HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  url = environment.bffUrl + '/v1';
  urlAdvisor = environment.advisorUrl + '/v1';

  constructor(private http: HttpClient) { }

  downloadDocument(documentId: number): any {
    return this.http.get(this.url + "/documents/download?documentId="+documentId, { responseType: 'blob' });
  }

    downloadDocumentAdvisor(documentId: number): any {
      return this.http.get(this.urlAdvisor + "/documents/download?documentId="+documentId, { responseType: 'blob' });
    }
}
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from "rxjs";
import { HttpError } from "./httpError";
import { tap } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private modalService: ModalService,
                private localizationService: LocalizationService,
                private spinnerService: NgxSpinnerService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap(event => {
            }, err => {
                this.spinnerService.hide();
                let errorMsgs = [];
                if (err instanceof HttpErrorResponse) {
                    errorMsgs.push(this.localizationService.translate('errorInterceptor.genericMessage'));
                    this.modalService.show({title: this.localizationService.translate('errorInterceptor.modalTitle'), texts: errorMsgs });
                    this.router.navigate(['/historical'], { skipLocationChange: true });
                }
            }));
        }
}
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: OAuthService, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!environment.ssoEnabled) {
      return true;
    }
    if (!this.auth.hasValidAccessToken()) {
      this.auth.initCodeFlow();
      return false;
    }
    return true;
  }
}
<div class="container-fluid container-creation">
    <div class="row-msx">
        <div class="col">
            <div class="btnClose">
                <a [routerLink]="['/']" (click)="cancel()" skipLocationChange><img src='{{siteUrl}}/assets/icon_remove.svg'></a>
            </div>
            <div class="attachments-advisor" *ngIf="creationService.selectedGroup.id === 4">
                <app-attachments [advisorPage]="true"></app-attachments>
            </div>
        </div>
    </div>
</div>

  <div  class="container-msx-fluid messagerie-container" *ngIf="!breakpointService.isDesktop">
    <div class="row-msx">
      <div class="col-12">
        <img class="back" (click)= "back()" src='{{siteUrl}}/assets/icon_left-arrow.svg'>
        <h4 class="h4-msx">{{ 'request-detail.backToMailBox' | translate }}</h4>
      </div>
    </div>
  </div>
  <div class="container-msx-fluid req-container" *ngIf="requestDetail && requestDetail.request as request">
    <div class="row-msx">
      <div class="col-8">
        <h2 class="h2-msx req-group">{{configService.lang === 'fr-FR' ? request.groupNameFR : request.groupNameEN}}</h2><div class="req-type">{{configService.lang === 'fr-FR' ? request.typeNameFR : request.typeNameEN}}</div>
        <div>
          <div class="req-date">{{ 'request-detail.requestDate' | translate }} {{formatDateTime(request.creationDateTime)}}</div>
          <div *ngIf="requestDetail.clientDocumentCount == 1" class="req-doc-count">- {{requestDetail.clientDocumentCount}} {{ 'request-detail.attachment' | translate }}</div>
          <div *ngIf="requestDetail.clientDocumentCount > 1" class="req-doc-count">- {{ 'request-detail.attachments' | translate }} : {{requestDetail.clientDocumentCount}}</div>
        </div>
      </div>
      <div class="col-4">
        <div class="req-sub-status">{{request.subStatus}}</div>
        <div class="request-status" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-closed': request.status === 'CLOSED' }">{{statusLabel(request.status)}}</div>
      </div>
    </div>
  </div>

  <div style="margin-bottom:100px;" class="container-msx-fluid msg-container" *ngIf="requestDetail && requestDetail.messages as messages">
    <div *ngFor="let message of messages; let i=index" class="row-msx">
      <div class="col col-mobile">
        <div class="single-msg" [class]="message.clientOrInternal == 'C' ? 'msg-client' : 'msg-internal'">
          <div class="msg-content">
            <div *ngIf="message.clientOrInternal == 'I'" class="msg-internal-title">
              <img class="icon-GS" src='{{siteUrl}}/assets/messages/icon_GS.svg' ><span>Gras Savoye</span>
            </div>

            <div class="msg-comment">{{message.comment}}</div>

            <div *ngFor="let document of message.documents" class="row-msx">
              <div class="col" >
                <div class="file" (click)="downloadDocument(document, requestDetail)" [ngClass]="{'downloadable':requestDetail.downloadable}">
                  <img class="icon-file" src='{{siteUrl}}/assets/icon_file.svg' >
                  <img *ngIf="![3,16].includes(requestDetail.request.typeId)" class="icon-download" src='{{siteUrl}}/assets/messages/icon_download.svg' >
                  <div class="file-name" >{{(document.name.length>25)? (document.name | slice:0:25)+'...':(document.name) }}</div>
                  <div class="file-size">{{formatBytes(document.size)}}</div>
                </div>
              </div>
            </div>

          </div>
          <div class="msg-date">{{formatDateTime(message.creationDateTime)}}</div>
        </div>
      </div>
    </div>
  </div>

  <footer *ngIf="advisorPage" style="border-top: 1px solid #D8D8D8;height: 100px;position: fixed;bottom: 0;left: 0;width: 100%;background-color: white;">
    <div class="container-msx" style="max-width: 900px;"><button type="button"
            class="btnCustom" style="background: #3E3B71 0% 0% no-repeat padding-box;float: right;margin-top: 30px;">REPONDRE
    </button>
    </div>
  </footer>
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { GroupComponent } from './group/group.component';
import { TypeComponent } from './type/type.component';
import { CreationComponent } from './creation.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import { UploadDirective } from '../../directives/upload.directive';
import { InternationalizationModule } from '../../internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/**
 * The http loader factory
 * @param {HttpClient} http
 * @returns {TranslateHttpLoader}
 * @constructor
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  declarations: [
    CreationComponent,
    GroupComponent,
    TypeComponent,
    AttachmentsComponent,
    UploadDirective
  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    FormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }
            })
  ],
  exports: [CreationComponent, UploadDirective, AttachmentsComponent]
})
export class CreationModule { }

import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Group } from 'src/app/models/group';
import { Type } from 'src/app/models/type';
import { UtilService } from 'src/app/services/util.service';
import { ConfigService } from 'src/app/services/config.service';
import { CreationService } from '../creation.service';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css','../creation.component.scss','./type.component.scss']
})
export class TypeComponent implements OnInit, OnChanges {
  types: Type[] = [];


  creationLabel = '';
  creationLabelCounter = 0;

  @Input() group: Group | undefined;

  constructor(
    private utilService: UtilService,
    public creationService: CreationService,
    public configService: ConfigService) {}

  ngOnInit(): void {
    this.getTypes();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getTypes();
  }

  onSelect(type: Type): void {
    this.creationService.selectedType = type;
    this.creationService.uploadFiles = new Array();
    this.creationService.requestMessage = '';
  }
  
  onKeyUp(event: any) {
    this.creationLabelCounter = event.target.value.length;
  }

  onSubmit() {
    this.creationService.step = 3;
    this.creationService.stepMax = 3;

  }

  checkButton() {
    if (this.creationService.selectedGroup.types.length == 0) {
      return false; // button is enabled
    } else if (this.creationService.selectedType != null) {
      return false; // button is enabled
    }
    return true; // button is disabled
  }

  getTypes(): void {
    if (this.group) {
      this.types = this.group.types;
    }
  }

  typeImage(typeId: number) {
    return this.utilService.getTypeImageUrl(typeId);
  }

}

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from './../../services/config.service';
import { LocalizationService } from 'src/app/internationalization/localization.service';

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./main-page.component.scss']
})
export class MainPageComponent implements OnInit {

  siteUrl = environment.siteUrl;
  extranet: string;
  welcomeImg: string;
  welcomeTextTop: string;
  videoLink: string;
  welcomeTextBottom: string;
  faqLink: string;

  constructor(private configService: ConfigService
             ,private localizationService: LocalizationService) { }

  ngOnInit(): void {
    this.extranet = this.configService.extranet;
    switch(this.extranet) {
       case 'EAC': {
            this.welcomeImg = 'Photo_HomePage_MSX.png';
            this.welcomeTextTop = this.localizationService.translate('mainPage.welcomeTextTopEAC');
            this.welcomeTextBottom = this.localizationService.translate('mainPage.welcomeTextBottomEAC');
            break;
       }
       case 'ERH': {
            this.videoLink = environment.videoUrlEXA;
            this.welcomeImg = 'Photo_HomePage_MSX.png';
            this.welcomeTextTop = this.localizationService.translate('mainPage.welcomeTextTopERH');
            this.welcomeTextBottom = this.localizationService.translate('mainPage.welcomeTextBottomERH');
            this.faqLink = environment.faqUrlERH;
            break;
       }
       case 'EXI': {
            this.videoLink = environment.videoUrlEXA;
            this.welcomeImg = 'Photo_HomePage_MSX.png';
            this.welcomeTextTop = this.localizationService.translate('mainPage.welcomeTextTopEXI');
            this.welcomeTextBottom = this.localizationService.translate('mainPage.welcomeTextBottomEXI');
            this.faqLink = environment.faqUrlEXA;
            break;
       }
       default: {
            this.videoLink = environment.videoUrlEXA;
            this.welcomeImg = 'Photo_HomePage_MSX.png';
            this.welcomeTextTop = this.localizationService.translate('mainPage.welcomeTextTopEXA');
            this.welcomeTextBottom = this.localizationService.translate('mainPage.welcomeTextBottomEXA');
            this.faqLink = environment.faqUrlEXA;
            break;
       }
    }
  }
  openFaq(): void {
    window.open(this.faqLink,"_self");
  }


}

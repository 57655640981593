export const environment = {
    production: false,
    bffUrl: 'https://int.msx.grassavoye.com/bff/api',
    advisorUrl: 'https://int.msx.grassavoye.com/advisor/api',
    siteUrl: 'https://int.msx.grassavoye.com',
    ssoEnabled: true,
    ssoUrlIssuer: 'https://sso-rct.grassavoye.com/openam/oauth2/realms/PTLQAL',
    ssoUrl: 'https://sso-rct.grassavoye.com/openam/oauth2/realms/PTLQAL',
    ssoRedirectUrl: 'https://int.msx.grassavoye.com',
    ssoRedirectUrlEXA: 'https://extranet-exa-int.witiwi.fr/fr/mailbox',
    ssoRedirectUrlERH: 'https://extranet-rh-int.witiwi.fr/fr/ma-messagerie',
    ssoRedirectUrlEAC: 'https://int.extranet-adp.grassavoye.com/fr/mailbox',
    ssoRedirectUrlEXI: 'https://extranet-exa-int.witiwi.fr/fr/mailbox',
    ssoClientId: 'msx-spa-int',
    videoUrlEXA: 'http://files.witiwi.fr/Marketing/Videos/Presentation_Messagerie_Espace_Client.mp4',
    faqUrlEXA: 'https://extranet-exa-int.witiwi.fr/fr/infos-pratiques/comment-faire',
    faqUrlERH: 'https://extranet-rh-int.witiwi.fr/fr/faq'
  };
import { last, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { GroupService } from 'src/app/services/group.service';
import { Request } from 'src/app/models/request';
import { RequestService } from 'src/app/services/request.service';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private filteredRequestsEmitter = new ReplaySubject<Request[]>();
  filteredRequests$ = this.filteredRequestsEmitter.asObservable();

  private groupListEmitter = new BehaviorSubject<any[]>([]);
  groupList$ = this.groupListEmitter.asObservable();
  
  statusList = [
    {code: 'SENT', selected: false}, 
    {code: 'PENDING', selected: false}, 
    {code: 'CLOSED', selected: false}
  ];
  
  constructor(private groupService: GroupService,private requestService: RequestService) { }

  initGroupList() {
    console.log("initGroupList")

    if (this.getGroupListValue().length === 0) {  
      //console.log("groupListValue.length === 0")    
      this.groupService.getGroups().subscribe(res => {
        let groupList = res.groups.map(g => { return {...g, selected: false}});
        console.log('emit groupList', groupList);
        this.groupListEmitter.next(groupList);
      });
    }
    //console.log('return groupList');
  }

  applyFilters(requests: Request[]) {
    console.log('applyFilters')
    this.filteredRequestsEmitter.next(this.filterRequests(requests));
  }

  getGroupListValue() {
    return this.groupListEmitter.value;
  }

  selectStatus(status) {
    this.statusList = this.statusList.map(s => {
      if (s.code === status.code) {
        s.selected = !s.selected
      };
      return s;
    })
  }

  selectGroup(group) {
    let groupList = this.getGroupListValue().map(g => {
      if (g.id === group.id) {g.selected = !g.selected};
      return g;
    });
    this.groupListEmitter.next(groupList);
  }

  hasAnyFilter(): boolean {
    if (this.statusList.find(s => s.selected) || (this.getGroupListValue().find(g => g.selected))) {
      return true;
    }
    return false;
  }
  hasOnlyStatusFilter(): boolean {
    if (this.statusList.find(s => s.selected) && !this.getGroupListValue().find(g => g.selected)) {
      return true;
    }
    return false;
  }
  hasOnlyGroupFilter(): boolean {
    if (!this.statusList.find(s => s.selected) && this.getGroupListValue().find(g => g.selected)) {
      return true;
    }
    return false;
  }
  hasStatusFilter(status): boolean {
    if (this.statusList.find(s => s.selected && s.code === status)) {
      return true;
    }
    return false;
  }
  hasGroupFilter(groupId): boolean {
    if (this.getGroupListValue().find(g => g.selected && g.id === groupId)) {
      return true;
    }
    return false;
  }

  private filterRequests(requests: Request[]): Request[] {
    console.log('filterRequests')
    return requests.map(r => {
        if (!this.hasAnyFilter()) {
          r.visible = true;
        }
        else if (this.hasOnlyStatusFilter()) {
          r.visible = this.hasStatusFilter(r.status) ? true : false;
        }
        else if (this.hasOnlyGroupFilter()) {
          r.visible = this.hasGroupFilter(r.groupId) ? true : false;
        }
        else {
          r.visible = this.hasStatusFilter(r.status) && this.hasGroupFilter(r.groupId) ? true : false;
        }          
        return r;
      });
  }

  cleanFilters() {
    this.statusList = this.statusList.map(s => {
      s.selected = false;
      return s;
    });
    let groupList = this.getGroupListValue().map(g => {
        g.selected = false;
        return g;
    });
    this.groupListEmitter.next(groupList);
    this.applyFilters(this.requestService.requests);
  }


}

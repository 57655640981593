import { FilterComponent } from './filters/filter.component';
import { HistoricalRequestComponent } from './historical-request.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { MainLayoutModule } from 'src/app/core/main-layout.module';
import { InternationalizationModule } from 'src/app/internationalization/internationalization.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient} from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../assets/locales/', '.json');
}

@NgModule({
  imports: [
    CommonModule,
    AppRoutingModule,
    MainLayoutModule,
    NgbModule,
    InternationalizationModule.forRoot({ locale_id: 'en-US' }),
            TranslateModule.forRoot({
              loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
              }
            })
  ],
  declarations: [
    HistoricalRequestComponent, 
    FilterComponent
  ],
  exports: [HistoricalRequestComponent, FilterComponent]
})
export class HistoricalRequestModule { }

import { RequestDetail } from './../../models/request-detail';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { RequestService } from 'src/app/services/request.service';
import { DocumentService } from 'src/app/services/document.service';
import { ConfigService } from 'src/app/services/config.service';
import { UtilService } from 'src/app/services/util.service';
import { environment } from 'src/environments/environment';
import { BreakpointService } from 'src/app/core/services/breakpoint.service';
import { Document } from 'src/app/models/document';
import { Request } from 'src/app/models/request';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./request-detail.component.scss']
})
export class RequestDetailComponent implements OnInit {
  siteUrl = environment.siteUrl;
  requestDetail: RequestDetail;
  advisorPage: boolean = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private configService: ConfigService,
    private utilService: UtilService,
    private requestService: RequestService,
    private documentService: DocumentService,
    public breakpointService: BreakpointService
  ) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      if (params.get("id")!== null){
        this.getRequestDetail(params.get("id"));
      }
    })
    this.route.queryParams.subscribe(params => {
        if (params.id !== null && params.id !== undefined){
            this.advisorPage = true;
            this.getRequestDetailForAdvisor(params.id);
        }
        })
  }

  getRequestDetailForAdvisor (id) {
    this.requestService.getRequestDetailForAdvisor(id).subscribe(resp => { this.requestDetail = resp;
        this.requestService.requests = this.sort(this.requestService.requests.slice());
    });
  }
  getRequestDetail(id) {
    this.requestService.getRequestDetail(id).subscribe(resp => { this.requestDetail = resp;
        //console.log(this.requestService.requests)
        //let requestIndex = this.requestService.requests.findIndex((request => request.id == id));
        //this.requestService.requests[requestIndex].unread = false;
        this.requestService.requests = this.sort(this.requestService.requests.slice());
    });

  }

  formatDateTime(date: Date) {
    return this.utilService.formatDateTime(date, this.configService.lang);
  }
  statusLabel(status: string) {
    return this.utilService.getStatusLabel(status);
  }

  back(){
    this.router.navigate(['/'], { skipLocationChange: true });
  }

  sort(data) {
    var sortedData = data.sort(function(x, y) {
      if (x.unread === y.unread) {
        return y.creationDateTime > x.creationDateTime;
      }
      return y.unread - x.unread;
    });
    return sortedData;
  }

  downloadDocument(document: Document, requestDetail: RequestDetail) {
        if(requestDetail.downloadable){
            this.documentService.downloadDocument(document.documentId)
                .subscribe(blob => {
                saveAs(blob,document.name)
                });
        } else if(this.advisorPage) {
            this.documentService.downloadDocumentAdvisor(document.documentId)
                .subscribe(blob => {
                saveAs(blob,document.name)
                });
        }
    }

    formatBytes(bytes){
        if (bytes === 0) return '0 Ko';

        const k = 1024;
        const sizes = ['Ko', 'Ko', 'Mo'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }
}





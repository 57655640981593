import { Component, OnInit } from '@angular/core';
import { BreakpointService } from '../services/breakpoint.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit {

  private isOutletActive = false;

  constructor(public breakpoints: BreakpointService) { }

  ngOnInit(): void {
  }

  onOutletActivated($event) {
      // Primarily here for debugging...
      this.isOutletActive = true;
    }

    onOutletDeactivated($event) {
      // Primarily here for debugging...
      this.isOutletActive = false;
    }

}

import { ConfigService } from './../../services/config.service';
import { CreationService } from './creation.service';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-creation',
  templateUrl: './creation.component.html',
  styleUrls: ['../../../styles/bootstrap.min.css','./creation.component.scss']
})
export class CreationComponent implements OnInit {
  siteUrl = environment.siteUrl;
  constructor(private configService: ConfigService, public creationService: CreationService) { }

  ngOnInit(): void {
  }
   cancel(){
     this.creationService.clean();
   }
   changeStep(step:string){
        if(step === 'next' && (this.creationService.stepMax > this.creationService.step)){
            ++this.creationService.step;
        }else if (step === 'before' && (1 < this.creationService.step && this.creationService.step < 4)){
            --this.creationService.step;
        }else if(this.creationService.stepMax >= Number(step)){
            this.creationService.step = Number(step);
        }
   }
}

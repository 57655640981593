import { AttachmentRule } from './../models/attachmentRule';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config } from '../models/config';


@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  extranet: string;
  profileName: string;
  lang: string;
  domain: string;
  attachmentRules: AttachmentRule[] = [];
  bffToken: string;
  ssoRedirectUrl: string;

  url = environment.bffUrl + '/v1/configurations';

  constructor(private http: HttpClient) { }

  getConfig(): Observable<HttpResponse<Config>> {
    console.log('getConfig');  
    return this.http.get<Config>(this.url, { headers: { "msx-extranet": this.extranet}, observe: 'response'});
  }

  /*
  getAttachmentRule(groupId: number, typeId?: number): AttachmentRule {
    console.log('getAttachmentRule');
    console.log('groupId', groupId);
    console.log('typeId', typeId);
    console.log('attachmentRules', this.attachmentRules);

    let result: AttachmentRule;

    if (this.attachmentRules.length === 0) 
      result = null;
    else {
      result = this.attachmentRules.find(rule => {
        if((rule.groupId === groupId) && (rule.typeId === typeId))
          return true;
      });
      
      console.log(result);
      
      return result;
    }
    
  }*/
  /*
  getAttachmentRuleByTypeId(typeId: number): AttachmentRule {
    if (this.attachmentRules.length === 0) 
      return null
    else {
      return this.attachmentRules.find(rule => {
        if(rule.typeId == typeId)
          return true;
      });
    }
  }
  */
}

import { DefaultComment } from './../../../models/defaultComment';
import { BindingService } from './../../../services/binding.service';
import { Binding } from './../../../models/binding';
import { ModalService } from './../../../services/modal.service';
import { FilterService } from 'src/app/pages/historical-request/filters/filter.service';
import { AttachmentRule } from './../../../models/attachmentRule';
import { ConfigService } from './../../../services/config.service';
import { Component, OnInit, Input } from '@angular/core';
import { CreationService } from '../creation.service';
import { RequestService } from '../../../services/request.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment'
import { BreakpointService } from '../../../core/services/breakpoint.service';
import { NgxSpinnerService } from "ngx-spinner";
import { LocalizationService } from 'src/app/internationalization/localization.service';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['../../../../styles/bootstrap.min.css','../creation.component.scss','./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit {
    siteUrl = environment.siteUrl;
    messageLength: number = 0;
    filesSize: number = 0;
    //attachmentRule: AttachmentRule;
    binding: Binding;
    submited = false;
    
    // test advisor page
    @Input() advisorPage: Boolean | undefined;

    constructor(
      private router: Router,
      private configService: ConfigService,
      private modalService: ModalService,
      public creationService: CreationService,
      private requestService: RequestService,
      private localizationService: LocalizationService,
      private bindingService: BindingService,
      private breakpointService: BreakpointService,
      private filterService: FilterService,
      private spinnerService: NgxSpinnerService) { }

    ngOnInit(): void {

      if(this.advisorPage) {
        
        const profileId: number = 1;
        const profileName: string = "EXAAdherent";	
        const groupId: number = this.creationService.selectedGroup.id;
        const typeId: number = this.creationService.selectedType ? this.creationService.selectedType.id : null;
        const attachmentRule: AttachmentRule = {id: 3, maxNb: 8, 
                                                 required: false, messageRequired: true, 
                                                 extensions: ["xls", "xlsx", "ppt", "pptx", "doc", "docx", "pdf", "gif", "jpeg", "jpg", "png", "bmp", "tiff", "html", "txt", "csv"]};
        const defaultComment: DefaultComment = null;
        const downloadable: boolean = false;                                       

        this.binding = {profileId, profileName, groupId, typeId, attachmentRule, defaultComment, downloadable};

      } else {
        this.bindingService.getBinding(
          this.creationService.selectedGroup.id, 
          this.creationService.selectedType ? this.creationService.selectedType.id : null
        ).subscribe(b => {this.binding = b; console.log(this.binding)});
      }
      
      /*
      this.attachmentRule = this.configService.getAttachmentRule(
        this.creationService.selectedGroup.id ,
        this.creationService.selectedType ? this.creationService.selectedType.id : null
      );*/
    }

    onKeyUp(event: any) {
      this.messageLength = event.target.value.length;
      this.validForm();
    }

    uploadFile(evt: FileList){
        let badFiles = [];
        let tooManyFiles = false;
        let errorMsgs = [];

        for (let i = 0; i < evt.length; i++) {
          if (this.isFileExtensionOk(evt[i]))
            if (this.creationService.uploadFiles.length < this.binding.attachmentRule.maxNb) {
              this.creationService.uploadFiles.push(evt[i]);
            } else {
              tooManyFiles = true;
            }
          else {
            badFiles.push(evt[i].name);
          }
        }

        
        if (badFiles.length > 0) {
          let badFilesMsg = (badFiles.length === 1) ? this.localizationService.translate('attachment.badFileExtension') : this.localizationService.translate('attachment.badFilesExtension');
          badFilesMsg += ' : ' + badFiles.join(', ');
          errorMsgs.push(badFilesMsg);
        }
        
        if (tooManyFiles) {
          if(!this.advisorPage) {
            errorMsgs.push(this.localizationService.translate('attachment.tooManyFiles'));
          }else {
            errorMsgs.push(this.localizationService.translate('attachment.advisorTooManyFiles'));
          }
        }

        if (this.isFilesSizeTooLarge(this.creationService.uploadFiles)) {
          if (this.creationService.uploadFiles.length > 1)
            errorMsgs.push(this.localizationService.translate('attachment.filesSizeTooLarge'));
          else 
            errorMsgs.push(this.localizationService.translate('attachment.fileSizeTooLarge'));
        }

        if (errorMsgs.length > 0) {
          this.modalService.show({title: this.localizationService.translate('attachment.modalTitle'), texts: errorMsgs });
        }

       this.validForm();
    }

    deleteFile(file){
        this.filesSize -= file.size;
        this.creationService.uploadFiles.splice(this.creationService.uploadFiles.indexOf(file),1);
        this.validForm();
    }

    cancel(){
        this.creationService.clean();
    }

    validForm(){
      if (this.creationService.uploadFiles.length > this.binding.attachmentRule.maxNb) {
        this.creationService.formValid = false;
      } else if ((this.creationService.uploadFiles.length === 0) && (this.binding.attachmentRule.required)) {
        this.creationService.formValid = false;
      } else if (this.isEmpty(this.creationService.requestMessage) && (this.binding.attachmentRule.messageRequired)) {
        this.creationService.formValid = false;
      } else if (this.isFilesSizeTooLarge(this.creationService.uploadFiles)) {
        this.creationService.formValid = false;
      } else if ((this.creationService.uploadFiles.length === 0) && this.isEmpty(this.creationService.requestMessage)) {
        this.creationService.formValid = false;
      } else {
        this.creationService.formValid = true;
      }
        //this.formValid = this.creationService.uploadFiles.length >= 1;
    }

    sendRequest(): void {
      this.submited = true;
      this.spinnerService.show();
      var request = {
        label : this.creationService.creationLabel,
        comment : this.creationService.requestMessage,
        groupId : this.creationService.selectedGroup.id,
        typeId : this.creationService.selectedType ? this.creationService.selectedType.id : null
      }

      this.requestService.createRequest(request, this.creationService.uploadFiles).subscribe(data => {
                                  this.spinnerService.hide();
                                  this.creationService.clean();
                                  this.submited = false;
                                  this.requestService.selectedId = data.id;

                                  if (this.breakpointService.isDesktop) {
                                    this.router.navigate(['/historical/requests-detail', data.id], { skipLocationChange: true });
                                  } else {
                                    this.router.navigate(['/requests-detail', data.id], { skipLocationChange: true });
                                  }

                                  console.log(data);
                                  return data.id;
                                });

    }

    isEmpty(text: string): boolean {
      return (text === null) || text.trim() === '';
    }

    isFileExtensionOk(file: File): boolean {
      const parts = file.name.trim().toLowerCase().split('.');
      if (parts.length < 2) {
        return false;
      }
      const extension = parts[parts.length-1].trim();

      const find = this.binding.attachmentRule.extensions.find(ext => {
        if (ext === extension)
          return true;
      });
      if (find)
        return true;
      else
        return false;
    }

    isFilesSizeTooLarge(files: File[]): boolean {
      const MAX_FILES_SIZE = 15 * 1024 * 1024;  //  15Mo
      this.filesSize = 0;

      for (let i = 0; i < files.length; i++) {
        this.filesSize += files[i].size;
      }
      if (this.filesSize > MAX_FILES_SIZE){

        return true;
      } else {

        return false;
       }
    }

     formatBytes(bytes){
        if (bytes === 0) return '0 Ko';

        const k = 1024;
        const sizes = ['Ko', 'Ko', 'Mo'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

}

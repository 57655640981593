<app-main-layout class="min-height-layout">
  <div class="container-msx-fluid">
    <div class="row-msx">
      <div class="col-10">
        <h1 class="h1-msx title">{{ 'historical.title' | translate }}</h1>
      </div>
      <div class="col-2">
        <button
          type="button"
          class="btn-filter"
          (click)="toggleFilters()">
          <img src='{{filterIcon()}}'>
        </button>        
      </div>
    </div>


    <app-filter *ngIf="showFilters"></app-filter>

    
      <div class="row-msx first-border">
        <div class="col btnEchange">
        <button 
          type="button" 
          class="btnCustom"
          [routerLink]="['/creation']"
          skipLocationChange="true"
          routerLinkActive="active">{{ 'historical.newRequest' | translate }}<img style="margin-left:20px;" src='{{siteUrl}}/assets/icon_plus.svg'>
        </button>
      </div>
    </div>
    
      <p *ngIf="hasNoResult()" class="no-result">
        {{ 'historical.noResult' | translate }}
      </p>

      <ul class="list-requests" *ngIf="requestService.requests">
        <ng-template ngFor let-request [ngForOf]="requestService.requests">
          <li *ngIf="request.visible" class="row-msx single-request" [class.selected]="requestService.selectedId === request.id" (click)="showDetail(request.id)">
            <div class="col-1 align-icon">
              <div *ngIf="groupImage(request.groupId) !== ''" class="group-img">
                <img src="{{groupImage(request.groupId)}}"  />
              </div>
            </div>
          
            <div class="col-6" [ngClass]="{'d-flex': isEmpty(request.label)}">
              <div class="type-name" [ngClass]="{'d-flex align-items-center': isEmpty(request.label)}" ngbTooltip="{{request.name}}" tooltipClass="tooltipFamily">{{ request.name.length>17 ? (request.name | slice:0:17)+'...': request.name}}</div>
              <div class="request-label">{{request.label}}</div>
            </div>
            <div class="col-2 request-date">{{format(request.creationDateTime)}}</div>
            <div class="col-2 request-status" [ngClass]="{'request-status-sent': request.status === 'SENT', 'request-status-pending': request.status === 'PENDING', 'request-status-closed': request.status === 'CLOSED' }">{{statusLabel(request.status)}}</div>
            <div *ngIf="request.unread" class="col-1 mark-unread"></div>
        </li>
        </ng-template>
      </ul>
    

  </div>
</app-main-layout>
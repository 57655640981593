import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Groups } from '../models/groups';


@Injectable({
  providedIn: 'root'
})
export class GroupService {
  
  url = environment.bffUrl + '/v1/groups';
  constructor(private http: HttpClient) { }

  getGroups(): Observable<Groups> {
    return this.http.get<Groups>(this.url);
  }
}

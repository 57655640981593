import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Modal } from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modalEmitter = new BehaviorSubject<Modal>(null);
  modal$ = this.modalEmitter.asObservable();

  constructor() { }

  show(newModal: Modal): void {
    this.modalEmitter.next(newModal);
  }

  hide(): void {
    this.modalEmitter.next(null);
  }
}

<div  class="container-fluid container-creation">
    <div class="row-msx">
        <div class="col">
            <h1 class="h1-msx title">{{ 'creation.title' | translate }}</h1>
            <div class="d-flex justify-content-center stepper">
                <img class="next" (click)= "changeStep('before')" [class.nextUnactive]="!(creationService.stepMax > 1) || creationService.step == 1"  src='{{siteUrl}}/assets/icon_before.svg'>
                <div class="stepperBtnActive" (click)="changeStep('1')">1</div>
                <div class="stepperBorder" [class.stepperBorderActive]="creationService.step >1"></div>
                <div class="stepperBtnActive" (click)="changeStep('2')" [class.stepperBtnUnactive]="creationService.step < 2">2</div>
                <div class="stepperBorder" [class.stepperBorderActive]="creationService.step > 2"></div>
                <div class="stepperBtnActive" (click)="changeStep('3')" [class.stepperBtnUnactive]="creationService.step !== 3">3</div>
                <img class="next" (click)= "changeStep('next')" [class.nextUnactive]="!(creationService.step < creationService.stepMax) || creationService.step == 3" src='{{siteUrl}}/assets/icon_next.svg'>
            </div>
            <div class="btnClose"><a (click)="cancel()" [routerLink]="['/']" skipLocationChange><img src='{{siteUrl}}/assets/icon_remove.svg'></a></div>
            <div *ngIf="creationService.step !== 3">
                <app-group></app-group>
            </div>
            <div *ngIf="creationService.step == 3">
                <app-attachments></app-attachments>
            </div>
        </div>
    </div>
</div>